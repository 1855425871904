import { TimeUnits } from 'shared/types/time-units'

const timeSpans: TimeUnits[] = ['m', 'h', 'd', 'w', 'mo', 'y']
const timeMultipliers = [1000 * 60, 60, 24, 7, 4.3, 12]

interface TimeSinceInterface {
  time: string
  unit: TimeUnits
}

const getTimeSince = (dateDiff: number, count = 0): TimeSinceInterface => {
  const time = dateDiff / timeMultipliers[count]

  if (time < 1) {
    const timePostfix = count - 1 >= 0 ? timeSpans[count - 1] : timeSpans[0]
    return { time: Math.ceil(count === 0 ? time : dateDiff).toString(), unit: timePostfix }
  }

  if (count === timeSpans.length - 1) {
    return { time: '>1', unit: timeSpans[count] }
  }

  return getTimeSince(time, count + 1)
}

export const getLastCommentDiff = (date: string) => {
  const now = new Date()
  const diff = Math.abs(now.getTime() - new Date(date).getTime())
  return getTimeSince(diff)
}
