import React from 'react'

function CommentOutlinedIcon(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.99979 16.9261C3.58161 16.9261 -3.12491e-05 13.3443 2.04483e-10 8.9259C2.04483e-10 6.80413 0.842826 4.76928 2.34307 3.26896C5.46766 0.144803 10.5332 0.145271 13.6572 3.26999C16.3814 5.99483 16.7765 10.2745 14.5972 13.4521C14.6981 13.8683 15.2783 15.0451 15.8124 15.9847C15.9633 16.2502 15.8704 16.5878 15.6049 16.7387C15.4384 16.8334 15.2348 16.8351 15.0667 16.7433C15.0611 16.7402 14.5014 16.4352 13.872 16.1331C12.99 15.7097 12.6086 15.5959 12.4642 15.5654C11.1457 16.4554 9.59054 16.9294 7.99979 16.9261ZM7.99979 2.03172C4.19847 2.03172 1.10588 5.12444 1.10588 8.92593C1.10588 12.7274 4.19847 15.8202 7.99979 15.8202C9.41323 15.8232 10.793 15.3889 11.95 14.5769C12.2596 14.36 12.6736 14.4563 13.0747 14.5927C13.3344 14.6812 13.6588 14.8146 14.0403 14.9902C13.8625 14.6225 13.7262 14.3102 13.6347 14.0604C13.4657 13.5991 13.396 13.2319 13.6054 12.9401C14.4464 11.7709 14.8973 10.3662 14.8938 8.92596C14.8938 5.12444 11.8012 2.03172 7.99979 2.03172Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default CommentOutlinedIcon
