export enum AttachmentTypeEnum {
  Image = 'image_upload',
  EmbeddedImage = 'image_link',
}

export enum AttachmentUploadStatusEnum {
  Uploading = 'Uploading',
  Success = 'Uploaded',
  Failed = 'Failed',
}

export interface PostAttachmentInterface {
  id?: number
  type: AttachmentTypeEnum
  status?: AttachmentUploadStatusEnum
  error?: string
  url?: string
  metadata?: string
  content?: string
  dataFileId?: number
}

export type PostAttachmentsApiCreateInterface = Pick<
  PostAttachmentInterface,
  'type' | 'url' | 'metadata' | 'content' | 'dataFileId'
>
