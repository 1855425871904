import { ComponentProps, ReactNode, useId } from 'react'
import { useTranslation } from 'next-i18next'
import { twMerge } from 'tailwind-merge'
import ClipIcon from 'shared/icons/clip-icon'
import { isAcceptTypeValid } from 'shared/utils/file-input-accept-types'

interface PlainUploaderProps extends Omit<ComponentProps<'input'>, 'onSelect' | 'onError'> {
  icon?: ReactNode
  onSelect: (file: File) => void
  acceptTypes?: string[]
  onError?: (error: string) => void
}

export default function PlainUploader({
  className,
  acceptTypes,
  icon,
  onSelect,
  onError,
  ...props
}: PlainUploaderProps) {
  const { t } = useTranslation()
  const generatedId = useId()

  const handleSelectFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0]

    if (file) {
      if (acceptTypes && !isAcceptTypeValid(file.type, acceptTypes)) {
        onError?.(t('file_selector.file_not_supported'))
      } else {
        onSelect(file)
      }
    }

    // NOTE: Reset value to have the ability to open the same file https://stackoverflow.com/questions/4109276/how-to-detect-input-type-file-change-for-the-same-file
    e.target.value = ''
  }

  const id = props.id ?? generatedId

  return (
    <label htmlFor={id} className={twMerge('cursor-pointer font-medium', className)}>
      <input
        type="file"
        className="invisible absolute h-1 w-0"
        onChange={handleSelectFile}
        id={id}
        {...props}
      />
      <span hidden>{t('global.upload_image')}</span>
      {icon ?? <ClipIcon className="h-6 w-6" />}
    </label>
  )
}
