import { useCallback, useEffect, useRef } from 'react'
import { useTranslation } from 'next-i18next'
import { RecentCommenterInterface as RecentCommenterInterface } from 'modules/community/types/post-info'

interface CommentAuthorPreviewProps {
  author: RecentCommenterInterface
}

const DEFAULT_AUTHOR_AVATAR = `/default_post_author_avatar.webp`

export default function CommentAuthorPreview({ author }: CommentAuthorPreviewProps) {
  const { t } = useTranslation()
  const ref = useRef<HTMLImageElement>(null)

  const handleLoadError = useCallback(() => {
    if (!ref.current || ref.current.src === DEFAULT_AUTHOR_AVATAR) return

    ref.current.src = DEFAULT_AUTHOR_AVATAR
  }, [])

  useEffect(() => {
    if (!ref.current) return

    ref.current.addEventListener('error', handleLoadError, { once: true })

    // eslint-disable-next-line react-hooks/exhaustive-deps
    return () => ref.current?.removeEventListener('error', handleLoadError)
  }, [handleLoadError])

  return (
    <img
      ref={ref}
      title={t('community.post_announce.post_info.avatar.title', { name: author.displayName })}
      alt={t('community.post_announce.post_info.avatar.alt', { name: author.displayName })}
      src={author.avatarUrl || DEFAULT_AUTHOR_AVATAR}
      className="h-6 w-6 rounded-full"
    />
  )
}
