import {
  PostAttachmentInterface,
  PostAttachmentsApiCreateInterface,
} from '../types/post-attachment'

export const escapeAttachmentLocalFields = (attachments?: PostAttachmentInterface[]) =>
  attachments?.map(
    attachment =>
      ({
        type: attachment.type,
        dataFileId: attachment.dataFileId,
      }) as PostAttachmentsApiCreateInterface,
  )

export const getPrimaryAttachment = (
  attachments?: PostAttachmentInterface[],
): PostAttachmentInterface | null => attachments?.[0] ?? null
