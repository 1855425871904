import React from 'react'

function EllipseIcon(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg
      width="3"
      height="3"
      viewBox="0 0 4 4"
      fill="CurrentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle opacity="0.4" cx="2" cy="2" r="2" fill="CurrentColor" />
    </svg>
  )
}

export default EllipseIcon
