import React from 'react'

function EmojiIcon(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_46038_917)">
        <path
          d="M12.5 0C5.60751 0 0 5.60751 0 12.5C0 19.3925 5.60751 25 12.5 25C19.3925 25 25 19.3925 25 12.5C25 5.60751 19.3926 0 12.5 0ZM12.5 23.6486C9.4378 23.6486 6.66014 22.4074 4.64331 20.402C3.83789 19.6011 3.15418 18.6781 2.62183 17.6638C1.811 16.1191 1.35137 14.3623 1.35137 12.5C1.35137 6.35262 6.35262 1.35137 12.5 1.35137C15.4158 1.35137 18.0733 2.47706 20.062 4.31611C21.0931 5.2695 21.9446 6.41444 22.5585 7.69419C23.257 9.15021 23.6486 10.7802 23.6486 12.5C23.6486 18.6474 18.6474 23.6486 12.5 23.6486Z"
          fill="#142D63"
        />
        <path
          d="M8.41778 11.0642C9.16412 11.0642 9.76915 10.4591 9.76915 9.71279C9.76915 8.96645 9.16412 8.36142 8.41778 8.36142C7.67143 8.36142 7.06641 8.96645 7.06641 9.71279C7.06641 10.4591 7.67143 11.0642 8.41778 11.0642Z"
          fill="#142D63"
        />
        <path
          d="M16.7791 11.0642C17.5254 11.0642 18.1305 10.4591 18.1305 9.71279C18.1305 8.96645 17.5254 8.36142 16.7791 8.36142C16.0328 8.36142 15.4277 8.96645 15.4277 9.71279C15.4277 10.4591 16.0328 11.0642 16.7791 11.0642Z"
          fill="#142D63"
        />
        <path
          d="M12.4764 19.4255C15.0908 19.4255 17.5963 18.0885 19.0309 15.8686L17.8958 15.1352C16.5785 17.1736 14.1689 18.3112 11.7561 18.0328C9.87586 17.816 8.13707 16.7327 7.10471 15.1352L5.96973 15.8686C7.2189 17.8018 9.32416 19.1127 11.6012 19.3753C11.8935 19.409 12.1854 19.4255 12.4764 19.4255Z"
          fill="#142D63"
        />
      </g>
      <defs>
        <clipPath id="clip0_46038_917">
          <rect width="25" height="25" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default EmojiIcon
