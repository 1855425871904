import { ComboboxOption, ListboxOption } from '@headlessui/react'
import { ReactNode } from 'react'
import { twMerge } from 'tailwind-merge'
import CheckIcon from 'shared/icons/check-icon'
import { DataInterface, ExtendedDataType } from './editable-form-select'

export type AsHeadlessType = typeof ComboboxOption | typeof ListboxOption

interface CommonListOptionProps<T extends ExtendedDataType> extends Record<string, unknown> {
  item: T | undefined
  getOptionDataAttribute?: (value?: string) => void
  value?: DataInterface<T>['id'] | DataInterface<T>['id'][]
  ['data-index']?: number
  optionRef?: (node: Element | null) => void
  optionIconRenderer?: (data?: T) => ReactNode | undefined
  firstRect?: boolean
  lastRect?: boolean
  as: AsHeadlessType
}

function CommonListOption<T extends ExtendedDataType>({
  item,
  getOptionDataAttribute,
  value,
  optionRef,
  optionIconRenderer,
  firstRect,
  lastRect,
  as: As,
  ...restProps
}: CommonListOptionProps<T>) {
  const caption = item?.customCaption || item?.caption
  const isCaptionEmpty = !caption
  return (
    <As
      ref={optionRef}
      value={item}
      className={({ focus }: { focus: boolean }) =>
        twMerge(
          'group relative flex items-center justify-between border-b border-gray/30 px-4 py-2.5 last:border-b-0 hover:bg-blue hover:text-white',
          !lastRect && 'last:rounded-b-lg',
          !firstRect && 'first:rounded-t-lg',
          focus ? 'bg-blue text-white' : 'bg-white text-darkblue',
        )
      }
      {...(getOptionDataAttribute ? getOptionDataAttribute(item?.caption) : {})}
      {...restProps}
    >
      {({ focus }: { focus: boolean }) => (
        <div
          className={twMerge(
            `flex w-full items-center justify-between gap-3`,
            isCaptionEmpty && 'text-transparent',
          )}
        >
          {isCaptionEmpty ? '.' : caption}
          <div className="flex gap-3">
            {(Array.isArray(value) ? value.includes(item?.id) : item?.id == value) && (
              <CheckIcon
                className={twMerge(
                  `h-4 w-4 group-hover:stroke-white`,
                  focus ? 'stroke-white' : 'stroke-blue',
                )}
                aria-hidden="true"
              />
            )}
            {optionIconRenderer && optionIconRenderer(item)}
          </div>
        </div>
      )}
    </As>
  )
}

export default CommonListOption
