import { MouseEventHandler } from 'react'
import { useTranslation } from 'next-i18next'
import FullscreenIcon from 'shared/icons/fullscreen-icon'
import { usePostAttachmentsContext } from 'modules/attachments/components/context'

interface FullscreenGalleryButtonProps {
  index: number
  className?: string
}

const FullscreenGalleryButton = ({ index, className }: FullscreenGalleryButtonProps) => {
  const { t } = useTranslation()
  const { setInitialGallerySlide, setIsGalleryVisible } = usePostAttachmentsContext()

  const handleOpenGallery: MouseEventHandler<HTMLButtonElement> = e => {
    e.stopPropagation()

    setInitialGallerySlide(index)
    setIsGalleryVisible(true)
  }

  return (
    <button
      type="button"
      className={className}
      onClick={handleOpenGallery}
      aria-label={t('post.attachments.open_gallery')}
    >
      <FullscreenIcon className="stroke-white" />
    </button>
  )
}

export default FullscreenGalleryButton
