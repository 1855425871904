export type FileInputAcceptType = string

export const fileInputAcceptTypes: Record<string, FileInputAcceptType[]> = {
  image: ['image/*'],
  jpg_jpeg_png: ['.jpg', '.png', '.jpeg'],
  all: [
    'image/*',
    'video/*',
    'audio/*',
    'application/*',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.ms-excel',
    'application/vnd.apple.numbers',
    'application/pdf',
    'text/*',
  ],
}

export const isAcceptTypeValid = (
  fileAcceptType: FileInputAcceptType,
  fileInputAcceptTypes: FileInputAcceptType[],
) =>
  fileInputAcceptTypes.some(fileInputAcceptType => RegExp(fileInputAcceptType).test(fileAcceptType))
