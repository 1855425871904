import React from 'react'
import { RenderLeafProps } from 'slate-react'
import { CustomText } from 'modules/community/types/CustomEditor'

export interface CustomRenderLeafProps extends RenderLeafProps {
  leaf: CustomText
}

export function Leaf({ attributes, children, leaf }: CustomRenderLeafProps) {
  if (leaf.bold) {
    children = <strong>{children}</strong>
  }

  if (leaf.italic) {
    children = <em>{children}</em>
  }

  return (
    <span {...attributes} className="pl-1">
      {children}
    </span>
  )
}
