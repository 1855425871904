import React from 'react'
import {
  ReactEditor,
  RenderElementProps,
  useFocused,
  useSelected,
  useSlateStatic,
} from 'slate-react'
import { Transforms } from 'slate'
import { twMerge } from 'tailwind-merge'
import TrashIcon from 'shared/icons/trash-icon'
import { ImageElement as ImageElementType } from 'modules/community/types/CustomEditor'

export interface CustomImageProps extends RenderElementProps {
  element: ImageElementType
}

export const ImageElement = ({ attributes, children, element }: any) => {
  const editor = useSlateStatic()
  const selected = useSelected()
  const focused = useFocused()
  const path = ReactEditor.findPath(editor, element)

  return (
    <div {...attributes}>
      <div contentEditable={false} className="relative">
        <img
          src={element.url}
          className={twMerge(
            'ml-auto mr-auto block max-w-full',
            selected && focused ? 'shadow-xl' : 'shadow-none',
          )}
        />
        <TrashIcon
          onClick={() => Transforms.removeNodes(editor, { at: path })}
          className={twMerge(
            'absolute right-2/3 top-0 cursor-pointer fill-bluegray hover:fill-red',
            selected && focused ? 'inline' : 'hidden',
          )}
          width="14px"
        />
      </div>
      {children}
    </div>
  )
}
