import React, { useCallback, useEffect } from 'react'

export const useOpenDropdownHandlers = ({
  setIsDropdownOpen,
}: {
  setIsDropdownOpen: React.Dispatch<React.SetStateAction<boolean>>
}) => {
  const handleKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (['ArrowUp', 'ArrowDown', 'Enter', ' '].includes(e.key)) {
        e.preventDefault()
        setIsDropdownOpen(true)
      }
    },
    [setIsDropdownOpen],
  )

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown)
    return () => document.removeEventListener('keydown', handleKeyDown)
  }, [handleKeyDown])
}
