import {
  AttachmentTypeEnum,
  PostAttachmentInterface,
} from 'modules/community/types/post-attachment'
import ImageAttachmentPreview from './image-preview'

export const previewFactory = (attachment: PostAttachmentInterface) => {
  switch (attachment.type) {
    case AttachmentTypeEnum.Image:
    case AttachmentTypeEnum.EmbeddedImage:
      return <ImageAttachmentPreview attachment={attachment} />
    default:
      return null
  }
}
