export default function TrashIconThin(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 11 11" fill="none" {...props}>
      <g clipPath="url(#prefix__clip0_46038_1016)" fill="#fff">
        <path d="M7.017 3.985a.258.258 0 00-.257.258v4.869a.258.258 0 00.515 0v-4.87a.258.258 0 00-.258-.257zM3.977 3.985a.258.258 0 00-.257.258v4.869a.258.258 0 00.515 0v-4.87a.258.258 0 00-.258-.257z" />
        <path d="M1.762 3.275v6.347c0 .375.137.727.378.98.239.253.572.397.92.398h4.874c.349 0 .682-.145.92-.398.241-.253.379-.605.379-.98V3.275A.984.984 0 008.98 1.34H7.661v-.322A1.012 1.012 0 006.641 0H4.354a1.012 1.012 0 00-1.02 1.018v.322h-1.32a.984.984 0 00-.252 1.935zm6.172 7.21H3.06c-.44 0-.783-.379-.783-.863V3.297h6.44v6.325c0 .484-.342.863-.783.863zM3.85 1.018a.496.496 0 01.505-.503H6.64a.496.496 0 01.505.503v.322H3.849v-.322zm-1.835.837H8.98a.464.464 0 110 .927H2.014a.464.464 0 110-.927z" />
        <path d="M5.498 3.985a.258.258 0 00-.258.258v4.869a.258.258 0 00.515 0v-4.87a.258.258 0 00-.257-.257z" />
      </g>
      <defs>
        <clipPath id="prefix__clip0_46038_1016">
          <path fill="#fff" d="M0 0h11v11H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}
