import React, { useEffect, useRef, useState } from 'react'
import { usePopper } from 'react-popper'
import { ReactEditor } from 'slate-react'
import { Editor, Range } from 'slate'
import { Loader } from 'shared/components/loader'
import { ListBox } from './ListBox/ListBox'
import { Suggestion } from './suggestion.types'

interface MentionSuggestionsProps {
  target: Range | null
  editor: Editor
  items: Suggestion[]
  onSelect: (item: Suggestion) => void
  isLoading: boolean
}

const transformMentionsToSuggestions = (mentions: Suggestion[]) =>
  mentions.map(mention => ({
    value: mention.userId.toString(),
    label: (
      <div className="flex h-8 items-center justify-start px-4">
        {mention.profileImageUrl && (
          <img
            className="mr-2 h-5 w-5 rounded-full"
            width={20}
            height={20}
            src={mention.profileImageUrl}
            alt={`Avatar of ${mention.displayName}`}
          />
        )}
        <strong className="text-[15px]">{mention.displayName}</strong>
      </div>
    ),
  }))

export const MentionSuggestions: React.FC<MentionSuggestionsProps> = ({
  target,
  editor,
  items,
  onSelect,
  isLoading,
}) => {
  const [listItems, setListItems] = useState<{ label: React.ReactNode; value: string }[]>([])

  const [referenceElement, setReferenceElement] = React.useState<HTMLDivElement | null>(null)
  const [popperElement, setPopperElement] = React.useState<HTMLDivElement | null>(null)
  const { styles: popperStyles, attributes: popperAttributes } = usePopper(
    referenceElement,
    popperElement,
    {
      placement: 'top-start',
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [0, 20],
          },
        },
      ],
    },
  )

  const setRefElement = useRef(setReferenceElement)

  useEffect(() => {
    if (target && items.length > 0) {
      const listItems = transformMentionsToSuggestions(items)
      setListItems(listItems)
    }
  }, [target, items])

  useEffect(() => {
    if (target) {
      const el = document.createElement('div')
      document.body.appendChild(el)
      setRefElement.current(el)
      const domRange = ReactEditor.toDOMRange(editor, target)
      const rect = domRange.getBoundingClientRect()
      el.style.position = 'absolute'
      el.style.top = `${rect.bottom + window.pageYOffset}px`
      el.style.left = `${rect.left + window.pageXOffset}px`

      return () => {
        document.body.removeChild(el)
        setRefElement.current(null)
      }
    }
  }, [target, editor])

  if (!target) return null

  return (
    <div ref={setReferenceElement}>
      <div
        ref={setPopperElement}
        style={{ zIndex: 100, ...popperStyles.popper }}
        {...popperAttributes.popper}
      >
        <div className="z-[100] my-1.5 max-h-[250px] w-max min-w-[260px] max-w-[420px] gap-2.5 overflow-hidden rounded-md bg-[rgba(248,248,248,1)] [box-shadow:rgba(29,28,29,0.13)_0px_0px_0px_1px,rgba(0,0,0,0.08)_0px_4px_12px_0px]">
          {!isLoading || listItems.length > 0 ? (
            <ListBox
              options={listItems}
              onChange={userId => {
                const suggestion = items.find(item => item.userId === Number(userId))
                if (suggestion) {
                  onSelect(suggestion)
                }
              }}
            />
          ) : (
            <div className="flex items-center justify-center py-3.5">
              <Loader type="light" />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
