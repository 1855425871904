import { useRouter } from 'next/router'
import useSWR from 'swr'
import { COMMUNITY_API_PREFIX } from 'shared/api/constants/api-constants'
import { useApiWrapperWithErrorValidation } from 'shared/hooks/use-api-wrapper-with-error-validation'
import { RequestMethodsEnum } from 'shared/hooks/use-api-wrapper-with-error-validation/types'
import useUser from 'shared/hooks/use-user'
import { Suggestion } from '../components/editor/elements/suggestion-mention/suggestion.types'

export function useSuggestionsList(displayNameSearchBy?: string) {
  const { user } = useUser()
  const router = useRouter()
  const { communityPath } = router.query

  const params = new URLSearchParams()
  if (displayNameSearchBy) {
    params.append('displayNameSearchBy', displayNameSearchBy)
  }

  const { fetcher } = useApiWrapperWithErrorValidation<RequestMethodsEnum.get, Suggestion[]>({
    method: RequestMethodsEnum.get,
  })

  return useSWR<Suggestion[]>(() => {
    if (communityPath && user) {
      return `${COMMUNITY_API_PREFIX}/${communityPath}/get-members-for-mention?${params.toString()}`
    }
    return null
  }, fetcher)
}
