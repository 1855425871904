import React from 'react'

function BoldIcon(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg width="11" height="14" viewBox="0 0 11 14" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M8.6 6.79C9.57 6.12 10.25 5.02 10.25 4C10.25 1.74 8.5 0 6.25 0H0V14H7.04C9.13 14 10.75 12.3 10.75 10.21C10.75 8.69 9.89 7.39 8.6 6.79V6.79ZM3 2.5H6C6.83 2.5 7.5 3.17 7.5 4C7.5 4.83 6.83 5.5 6 5.5H3V2.5ZM6.5 11.5H3V8.5H6.5C7.33 8.5 8 9.17 8 10C8 10.83 7.33 11.5 6.5 11.5Z" />
    </svg>
  )
}

export default BoldIcon
