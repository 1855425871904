import React from 'react'

function ItalicIcon(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg width="12" height="14" viewBox="0 0 12 14" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M5.79 11.5H7.5V13.5H0.5V11.5H2.79H3.12002L3.24975 11.1965L6.66975 3.19654L6.96752 2.5H6.21H4.5V0.5H11.5V2.5H9.21H8.87998L8.75025 2.80346L5.33025 10.8035L5.03248 11.5H5.79Z" />
    </svg>
  )
}

export default ItalicIcon
