import React from 'react'

const FullscreenIcon = (props: React.ComponentPropsWithoutRef<'svg'>) => {
  return (
    <svg
      width="30"
      height="31"
      viewBox="0 0 30 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.25 2.88635H5.625C4.87908 2.88635 4.16371 3.18267 3.63626 3.71011C3.10882 4.23756 2.8125 4.95293 2.8125 5.69885V11.3239C2.8125 11.5725 2.91127 11.8109 3.08709 11.9868C3.2629 12.1626 3.50136 12.2614 3.75 12.2614C3.99864 12.2614 4.2371 12.1626 4.41291 11.9868C4.58873 11.8109 4.6875 11.5725 4.6875 11.3239V5.69885C4.6875 5.45021 4.78627 5.21176 4.96209 5.03594C5.1379 4.86012 5.37636 4.76135 5.625 4.76135H11.25C11.4986 4.76135 11.7371 4.66258 11.9129 4.48676C12.0887 4.31095 12.1875 4.07249 12.1875 3.82385C12.1875 3.57521 12.0887 3.33676 11.9129 3.16094C11.7371 2.98512 11.4986 2.88635 11.25 2.88635Z"
        fill="white"
      />
      <path
        d="M24.375 2.88635H18.75C18.5014 2.88635 18.2629 2.98512 18.0871 3.16094C17.9113 3.33676 17.8125 3.57521 17.8125 3.82385C17.8125 4.07249 17.9113 4.31095 18.0871 4.48676C18.2629 4.66258 18.5014 4.76135 18.75 4.76135H24.375C24.6236 4.76135 24.8621 4.86012 25.0379 5.03594C25.2137 5.21176 25.3125 5.45021 25.3125 5.69885V11.3239C25.3125 11.5725 25.4113 11.8109 25.5871 11.9868C25.7629 12.1626 26.0014 12.2614 26.25 12.2614C26.4986 12.2614 26.7371 12.1626 26.9129 11.9868C27.0887 11.8109 27.1875 11.5725 27.1875 11.3239V5.69885C27.1875 4.95293 26.8912 4.23756 26.3637 3.71011C25.8363 3.18267 25.1209 2.88635 24.375 2.88635Z"
        fill="white"
      />
      <path
        d="M26.25 17.8864C26.0014 17.8864 25.7629 17.9851 25.5871 18.1609C25.4113 18.3368 25.3125 18.5752 25.3125 18.8239V24.4489C25.3125 24.6975 25.2137 24.936 25.0379 25.1118C24.8621 25.2876 24.6236 25.3864 24.375 25.3864H18.75C18.5014 25.3864 18.2629 25.4851 18.0871 25.6609C17.9113 25.8368 17.8125 26.0752 17.8125 26.3239C17.8125 26.5725 17.9113 26.811 18.0871 26.9868C18.2629 27.1626 18.5014 27.2614 18.75 27.2614H24.375C25.1209 27.2614 25.8363 26.965 26.3637 26.4376C26.8912 25.9101 27.1875 25.1948 27.1875 24.4489V18.8239C27.1875 18.5752 27.0887 18.3368 26.9129 18.1609C26.7371 17.9851 26.4986 17.8864 26.25 17.8864Z"
        fill="white"
      />
      <path
        d="M11.25 25.3864H5.625C5.37636 25.3864 5.1379 25.2876 4.96209 25.1118C4.78627 24.936 4.6875 24.6975 4.6875 24.4489V18.8239C4.6875 18.5752 4.58873 18.3368 4.41291 18.1609C4.2371 17.9851 3.99864 17.8864 3.75 17.8864C3.50136 17.8864 3.2629 17.9851 3.08709 18.1609C2.91127 18.3368 2.8125 18.5752 2.8125 18.8239V24.4489C2.8125 25.1948 3.10882 25.9101 3.63626 26.4376C4.16371 26.965 4.87908 27.2614 5.625 27.2614H11.25C11.4986 27.2614 11.7371 27.1626 11.9129 26.9868C12.0887 26.811 12.1875 26.5725 12.1875 26.3239C12.1875 26.0752 12.0887 25.8368 11.9129 25.6609C11.7371 25.4851 11.4986 25.3864 11.25 25.3864Z"
        fill="white"
      />
    </svg>
  )
}

export default FullscreenIcon
