import { Controller, useFormContext } from 'react-hook-form'
import TopicSelect from '../../topic-select'

export default function AddPostTopicSelect() {
  const { setValue, control } = useFormContext()

  return (
    <Controller
      control={control}
      name="topic"
      render={({ field, fieldState }) => (
        <div className="mb-6">
          <TopicSelect
            onChange={(topicId: number) => setValue('topic', topicId)}
            error={fieldState.error?.message}
            value={field.value}
          />
        </div>
      )}
    />
  )
}
