import useSWR from 'swr'
import { useApiWrapperWithErrorValidation } from 'shared/hooks/use-api-wrapper-with-error-validation'
import { RequestMethodsEnum } from 'shared/hooks/use-api-wrapper-with-error-validation/types'
import { ListLikePostByIdPostId } from 'shared/types/list-likes-post-by-post-id'

export default function useLastFewLikes({ postId }: { postId: string }) {
  const { fetcher } = useApiWrapperWithErrorValidation<
    RequestMethodsEnum.get,
    ListLikePostByIdPostId[]
  >({
    method: RequestMethodsEnum.get,
  })

  const { isValidating: isLoading, data: lastFewLikes } = useSWR(() => {
    return `/api/community/post/${postId}/list-last-few-likes`
  }, fetcher)

  return { lastFewLikes: lastFewLikes || [], isLoading }
}
