import React, { useCallback, useEffect } from 'react'
import { Option, THREE_NEXT_ELEMENTS_HEIGHT } from './ListBox'

const scrollToItem = (index: number, listRef: React.RefObject<HTMLUListElement>) => {
  const list = listRef.current
  if (list) {
    const item = list.children[index] as HTMLElement
    const itemTop = item.offsetTop
    const itemBottom = itemTop + item.clientHeight
    const listHeight = list.clientHeight

    if (itemTop < list.scrollTop) {
      list.scrollTop = itemTop - THREE_NEXT_ELEMENTS_HEIGHT
    } else if (itemBottom > list.scrollTop + listHeight) {
      list.scrollTop = itemBottom + THREE_NEXT_ELEMENTS_HEIGHT - listHeight
    }
  }
}

export const useDropdownKeyboardNavigation = ({
  optionsLength,
  activeIndex,
  setActiveIndex,
  selectOption,
  options,
  listRef,
}: {
  optionsLength: number
  activeIndex: number
  setActiveIndex: React.Dispatch<React.SetStateAction<number>>
  selectOption: (value: any) => void
  options: Option[]
  listRef: React.RefObject<HTMLUListElement>
}) => {
  const keyDownCallback = useCallback(
    (e: KeyboardEvent) => {
      switch (e.key) {
        case 'Up':
        case 'ArrowUp':
          e.preventDefault()
          setActiveIndex(prevIndex => {
            const newIndex = prevIndex > 0 ? prevIndex - 1 : optionsLength - 1
            scrollToItem(newIndex, listRef)
            return newIndex
          })
          return
        case 'Down':
        case 'ArrowDown':
          e.preventDefault()
          setActiveIndex(prevIndex => {
            const newIndex = prevIndex < optionsLength - 1 ? prevIndex + 1 : 0
            scrollToItem(newIndex, listRef)
            return newIndex
          })
          return
        case 'Enter':
        case ' ': // Space
          e.preventDefault()
          selectOption(options[activeIndex].value)
          return
        case 'Esc':
        case 'Escape':
          e.preventDefault()
          selectOption(false)
          return
        case 'PageUp':
        case 'Home':
          e.preventDefault()
          setActiveIndex(0)
          scrollToItem(0, listRef)
          return
        case 'PageDown':
        case 'End':
          e.preventDefault()
          setActiveIndex(optionsLength - 1)
          scrollToItem(optionsLength - 1, listRef)
          return
      }
    },
    [activeIndex, optionsLength, setActiveIndex, selectOption, options, listRef],
  )

  useEffect(() => {
    document.addEventListener('keydown', keyDownCallback)
    return () => document.removeEventListener('keydown', keyDownCallback)
  }, [keyDownCallback])
}
