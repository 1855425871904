import { twJoin, twMerge } from 'tailwind-merge'
import { Loader } from 'shared/components/loader'
import { usePostAttachmentsContext } from 'modules/attachments/components/context'
import FullscreenGalleryButton from 'modules/attachments/components/preview/fullscreen-gallery-button'
import {
  AttachmentUploadStatusEnum,
  PostAttachmentInterface,
} from 'modules/community/types/post-attachment'
import { previewFactory } from './preview-factory'
import RemoveAttachmentButton from './remove-button'

interface AttachmentPreviewItemProps {
  className?: string
  index?: number
  attachment: PostAttachmentInterface
}

export default function AttachmentPreviewItem({
  className,
  index = 0,
  attachment,
}: AttachmentPreviewItemProps) {
  const { isEditable } = usePostAttachmentsContext()
  const isLoading = attachment.status === AttachmentUploadStatusEnum.Uploading

  return (
    <div
      className={twMerge(
        'group relative h-fit w-fit overflow-hidden',
        isLoading && 'cursor-wait',
        className,
      )}
    >
      {previewFactory(attachment)}
      <div
        aria-hidden
        className={twJoin(
          'absolute left-0 top-0 z-10 h-full w-full rounded-lg bg-black opacity-0 transition-all',
          !isLoading && 'group-hover:opacity-75',
        )}
      />
      {isLoading && (
        <Loader
          iconClassName="h-16 w-16"
          className="absolute left-[50%] top-[50%] h-16 w-16 -translate-x-[50%] -translate-y-[50%]"
        />
      )}
      {!isLoading && (
        <>
          <FullscreenGalleryButton
            index={index}
            className="absolute left-1/2 top-1/2 z-20 -translate-x-1/2 -translate-y-1/2 transform opacity-0 group-hover:opacity-100"
          />
          {isEditable && (
            <RemoveAttachmentButton
              id={attachment.dataFileId!}
              className="absolute -top-2 right-2 z-20 opacity-0 transition-all group-hover:top-2 group-hover:opacity-100"
            />
          )}
        </>
      )}
    </div>
  )
}
