import { type FocusEvent } from 'react'
import { useFormContext } from 'react-hook-form'
import FormInputRhf from 'shared/components/form-input-rhf'

interface AddPostInputFieldProps {
  name: string
  label: string
  maxLength?: number
  onFocus?: (event: FocusEvent<HTMLInputElement>) => void
}

export default function AddPostInputField(props: AddPostInputFieldProps) {
  const { control, setValue, watch, formState } = useFormContext()

  const error = formState.errors[props.name]?.message as string

  return (
    <div className="mb-6">
      <div className="relative font-medium">
        <FormInputRhf
          name={props.name}
          setValue={setValue}
          control={control}
          maxLength={props.maxLength}
          label={props.label}
          onFocus={props.onFocus}
        />
        {props.maxLength !== undefined && (
          <span className="absolute right-1 top-9 text-darkblue/50">
            {watch(props.name).length} / {props.maxLength}
          </span>
        )}
      </div>
      {error && <p className="mt-2 text-sm text-red">{error}</p>}
    </div>
  )
}
