import { SVGProps } from 'react'

export default function DownloadIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fill="CurrentColor"
        d="M16.5 16.9043H1.5M14 8.57096L9 13.571M9 13.571L4 8.57096M9 13.571V1.9043"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
