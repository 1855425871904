import { useTranslation } from 'next-i18next'
import CommentOutlinedIcon from 'shared/icons/comment-outlined-icon'
import { PostCommentInfoResult } from 'modules/community/types/post-info'
import CommentAuthorPreview from './comment-author-preview'
import { getLastCommentDiff } from './utils'

interface PostCommentsInfoProps {
  info?: PostCommentInfoResult
}

export default function PostCommentsInfo({ info }: PostCommentsInfoProps) {
  const { t } = useTranslation()

  if (!info) {
    return <PostCommentsInfoSkeleton />
  }

  if (!info.ok)
    return (
      <div className="group flex items-center gap-2 rounded-md px-1.5 py-2 transition-colors hover:bg-blue/20">
        <CommentOutlinedIcon className="h-6 w-6 text-bluegray transition-colors group-hover:text-blue" />
      </div>
    )

  const infoValue = info.value
  const commentsCount = infoValue.numberOfComments
  const timeSince =
    infoValue.lastCommentPostedAt && getLastCommentDiff(infoValue.lastCommentPostedAt)

  return (
    <div className="flex items-center gap-2">
      <div className="group flex items-center gap-2 rounded-md px-1.5 py-2 transition-colors hover:bg-blue/20">
        <CommentOutlinedIcon className="h-6 w-6 text-bluegray transition-colors group-hover:text-blue" />
        {commentsCount > 0 ? commentsCount : ''}
      </div>
      <div className="hidden gap-0.5 sm:flex lg:flex">
        {infoValue.recentCommentators.map(author => (
          <CommentAuthorPreview author={author} key={`comment_author_${author.userId}_avatar`} />
        ))}
      </div>
      {timeSince && (
        <span className="text-blue">
          {t('community.post_announce.post_info.last_comment_time', {
            time: timeSince.time + t(timeSince.unit),
          })}
        </span>
      )}
    </div>
  )
}

function PostCommentsInfoSkeleton() {
  return <div className="max-auto h-7 w-52 animate-pulse rounded bg-gray" />
}
