import { useState } from 'react'
import { useTranslation } from 'next-i18next'
import EditableFormSelect from 'shared/components/editable-form-select'
import { OptionsInterface } from 'shared/components/form-select'
import { BadRequest } from 'shared/errors/bad-request'
import { InternalError } from 'shared/errors/internal-error'
import useUser from 'shared/hooks/use-user'
import { createTopic } from '../api/topic-api'
import { generatePathFromName } from '../hooks/path-utils'
import { useCommunityWithTopics } from '../hooks/use-community-with-topics'

interface TopicSelectProps {
  error?: string
  value?: number
  onChange: (topicId: number) => void
}

export default function TopicSelect({ error, value, onChange }: TopicSelectProps) {
  const { t } = useTranslation()

  const [createError, setCreateError] = useState<string>()
  const [isCreating, setIsCreating] = useState(false)

  const { isOwner } = useUser()

  const { data: community, mutate } = useCommunityWithTopics()
  const topics = community?.topics

  const selected = topics?.find(topic => topic.id === value)

  const options = topics?.map(topic => ({ id: topic.id, caption: topic.name }) as OptionsInterface)

  const createNewTopic = async ({ name }: { name: string }) => {
    if (!community) return

    setIsCreating(true)

    try {
      const { data } = await createTopic({ name, path: generatePathFromName(name) }, community)

      await mutate(
        {
          ...community,
          topics: [...community.topics, data],
        },
        false,
      )
      onChange(data.id)
    } catch (e) {
      let errorMessage: string = ''
      if (e instanceof BadRequest) {
        errorMessage = Object.values(e.errors?.fields ?? {})
          .concat(e.errors.common)
          .map(err => err.join('/n'))
          .join('/n')
      } else if (e instanceof InternalError) {
        errorMessage = t('core.error.internal_error_message')
      }

      setCreateError(errorMessage)
    }

    setIsCreating(false)
  }

  const handleChangeTopic = (data: number | string) => {
    setCreateError('')
    onChange(Number(data))
  }

  const errorMessage = [createError, error].filter(err => !!err).join('<br />')

  return (
    <EditableFormSelect
      htmlFor={'none'}
      labelClassName={'w-full text-darkblue'}
      isCreating={isCreating}
      label={t('home.add_post.topic')}
      placeholder={t(
        isOwner ? 'home.add_topic.choose_or_create_topic' : 'home.add_post.choose_topic',
      )}
      value={value}
      data={options}
      staticData={selected && [{ id: selected.id, caption: selected.name }]}
      onChange={handleChangeTopic}
      disabled={isCreating}
      createData={
        isOwner
          ? {
              setCreateData: createNewTopic,
              getCustomCaption: data =>
                t('home.add_topic.create_option', { name: data, path: generatePathFromName(data) }),
            }
          : undefined
      }
      error={errorMessage}
    />
  )
}
