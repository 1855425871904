import { Popover, PopoverPanel } from '@headlessui/react'
import React, { useEffect, useRef, useState } from 'react'
import { usePopper } from 'react-popper'
import { ReactEditor, useSlate } from 'slate-react'
import { Editor, Range } from 'slate'
import BoldIcon from '../../shared/icons/bold-icon'
import ItalicIcon from '../../shared/icons/italic-icon'
import ListIcon from '../../shared/icons/list-icon'
import NumericListIcon from '../../shared/icons/numeric-list-icon'
import SaveIcon from '../../shared/icons/save-icon'
import { insertLink } from '../community/hooks/slate-editor-utils'
import BlockButton from './block-button'
import LinkButton from './link-button'
import MarkButton from './mark-button'

const computeAnchorEl = () => {
  const native = window.getSelection()
  const range = native!.getRangeAt(0)
  const rect = range.getBoundingClientRect()
  return {
    clientWidth: rect.width,
    clientHeight: rect.height,
    getBoundingClientRect: () => range.getBoundingClientRect(),
  }
}

type AnchorElement = {
  clientWidth: number
  clientHeight: number
  getBoundingClientRect: () => DOMRect
}

export default function HoveringToolbar() {
  const [open, setOpen] = useState(false)
  const editor = useSlate()
  const { selection } = editor
  let [referenceElement, setReferenceElement] = useState<AnchorElement>()
  let [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null)
  let { styles, attributes } = usePopper(referenceElement, popperElement)
  const [linkIsEditing, setLinkIsEditing] = useState(false)
  const inputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (
      !selection ||
      !ReactEditor.isFocused(editor) ||
      Range.isCollapsed(selection) ||
      Editor.string(editor, selection) === ''
    ) {
      setOpen(false)
      return
    }
    setReferenceElement(computeAnchorEl())
    setOpen(true)
  }, [editor, selection])

  const handleSaveLink = () => {
    if (inputRef.current) {
      const url = inputRef.current.value
      url && insertLink(editor, url)
      setLinkIsEditing(false)
    }
  }

  function closeOnEnterKey(event: React.KeyboardEvent<HTMLInputElement>) {
    if (event.key === 'Enter') {
      handleSaveLink()
    }
  }

  const popperRefCallback = (element: HTMLDivElement | null) => {
    setPopperElement(element)
  }

  return (
    <Popover>
      <>
        {open && (
          <div>
            <PopoverPanel
              static
              className="absolute left-1/2 z-10 mt-3 rounded-lg bg-white p-2 shadow-md"
              ref={popperRefCallback}
              style={styles.popper}
              {...attributes.popper}
            >
              {!linkIsEditing && (
                <>
                  <MarkButton format="bold" label="bold">
                    <BoldIcon height="15px" />
                  </MarkButton>
                  <MarkButton format="italic" label="italic">
                    <ItalicIcon height="15px" />
                  </MarkButton>
                  <BlockButton format="bulleted-list" label="bulleted-list">
                    <ListIcon height="15px" />
                  </BlockButton>
                  <BlockButton format="numbered-list" label="numbered-list">
                    <NumericListIcon height="15px" />
                  </BlockButton>
                  <LinkButton setLinkIsEditing={setLinkIsEditing} format="link" label="link" />
                </>
              )}
              {linkIsEditing && (
                <div className="flex">
                  <input
                    className="focus:border-blue-400 block w-32 border-2"
                    ref={inputRef}
                    onKeyDown={closeOnEnterKey}
                  />
                  <SaveIcon
                    className="ml-1 cursor-pointer self-center fill-bluegray"
                    height="20px"
                    onClick={handleSaveLink}
                  />
                </div>
              )}
            </PopoverPanel>
          </div>
        )}
      </>
    </Popover>
  )
}
