import { useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import toast from 'react-hot-toast'
import { useSnapCarousel } from 'react-snap-carousel'
import { useTranslation } from 'next-i18next'
import CloseIcon from 'shared/icons/close-icon'
import DownloadIcon from 'shared/icons/download-icon'
import ThinArrowRightIcon from 'shared/icons/thin-arrow-right-icon'
import { usePostAttachmentsContext } from 'modules/attachments/components/context'

export interface AttachmentGalleryProps {}

export const AttachmentGallery = () => {
  const { t } = useTranslation()
  const { attachments, initialGallerySlide, setIsGalleryVisible } = usePostAttachmentsContext()
  const [isInitialized, setIsInitialized] = useState(false)

  const { scrollRef, activePageIndex, hasPrevPage, hasNextPage, prev, next, goTo } =
    useSnapCarousel()

  useEffect(() => {
    if (isInitialized || initialGallerySlide === undefined) return

    goTo(initialGallerySlide, { behavior: 'instant' as ScrollBehavior })
    setIsInitialized(true)
  }, [isInitialized, goTo, initialGallerySlide])

  // native download only works for same-origin uris
  const handleDownload = async () => {
    const activeAttachment = attachments?.[activePageIndex]

    if (!activeAttachment?.url) {
      toast.error(t('core.error.internal_error_message'))
      return
    }

    const blob = await fetch(activeAttachment.url).then(res => res.blob())
    const url = URL.createObjectURL(blob)

    const link = document.createElement('a')
    link.href = url
    link.toggleAttribute('download')
    document.body.appendChild(link)
    link.click()
    link.remove()
  }

  // keyboard navigation support
  useEffect(() => {
    const handle = (e: KeyboardEvent) => {
      e.stopPropagation()

      switch (e.key) {
        case 'ArrowLeft':
          prev()
          return
        case 'ArrowRight':
          next()
          return
        case 'Escape':
          setIsGalleryVisible(false)
          return
        default:
          return
      }
    }

    window.addEventListener('keydown', handle, true)

    return () => {
      window.removeEventListener('keydown', handle, true)
    }
  }, [next, prev, setIsGalleryVisible])

  return createPortal(
    <div className="fixed bottom-0 left-0 right-0 top-0 z-[1001] bg-black">
      <section
        className="hidden-scrollbar flex h-full snap-x snap-mandatory overflow-x-auto overscroll-contain"
        ref={scrollRef}
      >
        {attachments?.map(({ id, url }) => (
          <article
            key={id}
            className="inline-flex w-full flex-shrink-0 snap-center justify-center px-20 py-10 lg:px-80 lg:py-40"
          >
            <img className="object-contain" src={url} alt={url} />
          </article>
        ))}
      </section>

      <div>
        {hasPrevPage && (
          <button
            aria-label={t('global.previous')}
            className="absolute left-3 top-1/2 -translate-y-1/2 transform lg:left-12"
            onClick={() => prev()}
          >
            <ThinArrowRightIcon className="rotate-180 stroke-white" />
          </button>
        )}
        {hasNextPage && (
          <button
            aria-label={t('global.next')}
            className="absolute right-3 top-1/2 -translate-y-1/2 transform lg:right-12"
            onClick={() => next()}
          >
            <ThinArrowRightIcon className="stroke-white" />
          </button>
        )}
      </div>

      <div className="absolute right-3 top-3 inline-flex items-center gap-8 lg:right-12 lg:top-12">
        <button type="button" onClick={handleDownload}>
          <DownloadIcon className="cursor-pointer fill-white" height={21} width={21} />
        </button>
        <button type="button" onClick={() => setIsGalleryVisible(false)}>
          <CloseIcon className="cursor-pointer fill-white" height={25} width={25} />
        </button>
      </div>
    </div>,
    document.body,
  )
}
