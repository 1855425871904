import { useCallback, useEffect, useRef, useState } from 'react'
import { isSafari } from 'shared/utils/is-safari'
import { Option } from './ListBox'
import { useDropdownKeyboardNavigation } from './use-dropdown-keyboard-navigation'
import { useOpenDropdownHandlers } from './use-open-dropdown-handlers'

export const useAccessibleDropdown = ({
  options,
  onChange,
}: {
  options: Option[]
  onChange: (value: number) => void
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false)
  const [activeIndex, setActiveIndex] = useState(0)
  const listRef = useRef<HTMLUListElement>(null)

  const selectOption = useCallback(
    (value: number) => {
      if (value) {
        onChange(value)
      }
      setIsDropdownOpen(false)
    },
    [onChange],
  )

  useDropdownKeyboardNavigation({
    optionsLength: options.length,
    activeIndex,
    setActiveIndex,
    selectOption,
    options,
    listRef,
  })

  useOpenDropdownHandlers({ setIsDropdownOpen })

  useEffect(() => {
    if (isDropdownOpen && listRef.current && isSafari()) {
      requestAnimationFrame(() => listRef.current?.focus())
    } else if (listRef.current && isSafari()) {
      requestAnimationFrame(() => (listRef.current?.previousSibling as HTMLElement)?.focus())
    }
  }, [isDropdownOpen])

  return {
    isDropdownOpen,
    setIsDropdownOpen,
    activeIndex,
    setActiveIndex,
    selectOption,
    listRef,
  }
}
