import React, { useEffect } from 'react'
import { useAccessibleDropdown } from './use-accessible-dropdown'

export interface Option {
  value: string
  label: React.ReactNode
}

export interface Props {
  options: Option[]
  onChange: (value: number) => void
}

export const THREE_NEXT_ELEMENTS_HEIGHT = 80

export const ListBox: React.FC<Props> = ({ options, onChange }) => {
  const { setIsDropdownOpen, activeIndex, setActiveIndex, selectOption, listRef } =
    useAccessibleDropdown({
      options,
      onChange,
    })

  useEffect(() => {
    setIsDropdownOpen(true)
  }, [setIsDropdownOpen])

  return (
    <>
      <ul
        ref={listRef}
        className="text-gray-[#333] m-0 box-border max-h-[inherit] min-w-full origin-top list-none overflow-y-auto bg-white px-0 py-2 text-xl transition-transform duration-200"
        role="listbox"
      >
        {options.map(({ label: Label, value: optionValue }, index) => (
          <li
            key={optionValue}
            id={`select_option_${optionValue}`}
            role="option"
            aria-selected={index === activeIndex}
            onMouseOver={() => setActiveIndex(index)}
            onClick={() => selectOption(Number(optionValue))}
            className="aria-selected:bg-[#1264a3] aria-selected:text-white"
          >
            <label className="font- font-sans box-border flex w-full cursor-pointer">
              <input
                type="radio"
                name="select_radio"
                value={optionValue}
                onChange={() => selectOption(index)}
                className="m-0 h-0 w-0 overflow-hidden p-0 opacity-0"
              />
              <span>{Label}</span>
            </label>
          </li>
        ))}
      </ul>
    </>
  )
}
