import React, { useState } from 'react'
import { useTranslation } from 'next-i18next'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { twMerge } from 'tailwind-merge'
import { LocaleEnum } from 'shared/enums/locale-enum'
import useWindowSize from 'shared/hooks/use-window-size'
import { handleWithoutPropagating } from 'shared/utils/handle-without-propagating'
import { PostAttachmentsProvider } from 'modules/attachments/components/context'
import AttachmentPreviewItem from 'modules/attachments/components/preview/preview-item'
import LikeButton from 'modules/community/components/like/like-button'
import PostModalEdit from 'modules/community/components/post-modal-edit'
import { PostStatusEnum } from 'modules/community/enums/post-status-enum'
import { useIsPostEditable } from 'modules/community/hooks/use-is-post-editable'
import { PostInterface } from 'modules/community/types/post-interface'
import { getPrimaryAttachment } from 'modules/community/utils/attachments'
import { timeSince } from 'modules/post/utils/time-utils'
import { TextWithMentions } from '../editor/utils/deserealizeMentionToHTML'
import { PostAuthorInfo } from './author-info'
import PostCommentsInfo from './comments-info'
import PostModal from './post-modal'

type PostAnnounceProps = {
  post: PostInterface
  deletePost: () => void
  deletePostAndBanUser: () => void
  handleLikeClick?: () => void
  dashboardLocale?: LocaleEnum
}

const MOBILE_BREAKPOINT = 768
const MAX_CONTENT_LENGTH = 360
const MAX_CONTENT_LENGTH_MOBILE = 125

function PostAnnounce({
  post,
  deletePost,
  handleLikeClick,
  dashboardLocale,
  deletePostAndBanUser,
}: PostAnnounceProps) {
  const { t } = useTranslation()
  const router = useRouter()
  const { width } = useWindowSize()

  const { communityPath, topicPath: topicPathParam } = router.query
  const communityPathWithSlug = `/community/${communityPath}`
  const topicPath = `/community/${communityPath}/${post.topic.path}`
  const postDetailsPath = `${topicPath}/${post.path}`
  const isPostEditable = useIsPostEditable(post)

  const [opened, setOpened] = useState(false)

  const isMobile = width <= MOBILE_BREAKPOINT

  const handleModalOpen = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const target = e.target as HTMLElement
    const isLink = target.tagName.toLowerCase() === 'a'

    if (!isLink) {
      if (window) {
        window.history.replaceState(null, '', postDetailsPath)
      }
      setOpened(true)
    }
  }

  const handleModalClose = () => {
    if (window) {
      window.history.replaceState(null, '', topicPathParam ? topicPath : communityPathWithSlug)
    }
    setOpened(false)
  }

  const commentsCount = post.postCommentsInfo?.ok
    ? (post.postCommentsInfo.value?.numberOfComments ?? 0)
    : 0

  const primaryAttachment = getPrimaryAttachment(post.attachments)

  return (
    <PostAttachmentsProvider attachments={post.attachments}>
      <div
        onClick={handleModalOpen}
        className="mb-8 w-full cursor-pointer rounded-lg border border-[#eceef1] bg-white p-10 shadow-[0px_4px_20px_0px_#F2F4F5] transition-shadow hover:shadow-[0px_4px_25px_0px_rgb(217,221,223)]"
      >
        <div className="mb-5 flex justify-between">
          <div className="flex flex-wrap items-center gap-2.5">
            <PostAuthorInfo author={post.author} userId={post.userId} />
            <div className="bluegray inline lg:block">
              <span>{timeSince(new Date(post.createdAt), dashboardLocale)}</span>{' '}
              {t('community.pages_on')}{' '}
              <Link className="font-semibold text-darkblue" href={topicPath} shallow>
                #{post.topic.name}
              </Link>
            </div>
          </div>
          {isPostEditable && (
            <PostModalEdit
              post={post}
              deletePost={deletePost}
              deletePostAndBanUser={deletePostAndBanUser}
            />
          )}
        </div>
        <div className="flex flex-col items-start justify-between gap-2 lg:flex-row">
          <div className="w-full">
            <h2 className="community-post-title pb-2 md:pb-3">{post.title}</h2>
            <TextWithMentions
              className={twMerge(
                'preview-image-wrapper cursor-pointer overflow-hidden text-ellipsis break-all text-sm text-darkblue/90 lg:text-base',
              )}
              truncateLength={isMobile ? MAX_CONTENT_LENGTH_MOBILE : MAX_CONTENT_LENGTH}
              text={post.content}
              mentions={post.mentions}
            />
            <button className="pt-2.5 leading-none text-blue hover:underline md:pt-3.5">
              {t('post_announce.post_show_more')}
            </button>
          </div>
          {!!primaryAttachment && (
            <AttachmentPreviewItem className="min-w-fit" attachment={primaryAttachment} />
          )}
        </div>
        {post.status === PostStatusEnum.Approved && (
          <div className="mt-3 flex items-center gap-3">
            {handleLikeClick && (
              <div onClick={handleWithoutPropagating()}>
                <LikeButton
                  isLiked={post.isLiked}
                  likesCount={post.likesCount}
                  handleLikeClick={handleLikeClick}
                  postId={post.id}
                />
              </div>
            )}
            <PostCommentsInfo info={post.postCommentsInfo} />
          </div>
        )}
      </div>
      <PostModal
        opened={opened}
        onClose={handleModalClose}
        post={post}
        handleLikeClick={handleLikeClick}
        dashboardLocale={dashboardLocale}
        topicPath={topicPath}
        commentsCount={commentsCount}
      />
    </PostAttachmentsProvider>
  )
}

export function PostAnnounceSkeleton() {
  return (
    <div className="mb-10 animate-pulse overflow-hidden rounded-lg bg-white">
      <div className="w-full px-5 py-5 lg:px-16 lg:py-10">
        <div className="mb-4 flex items-center gap-2">
          <div className="h-6 w-6 rounded-full bg-gray" />
          <div className="h-4 w-14 rounded-sm bg-gray" />
          <div className="h-4 w-14 rounded-sm bg-gray" />
        </div>
        <div className="mb-2 h-7 rounded-sm bg-gray" />
        <div className="mb-5 h-7 w-3/4 rounded-sm bg-gray" />
        {Array.from(Array(6).keys()).map(index => (
          <div className="mb-3 h-3 rounded-sm bg-gray" key={index} />
        ))}
      </div>
      <hr className="mx-5 px-5 text-gray lg:mx-0" />
      <div className="flex w-full justify-between px-5 py-5 lg:px-16 lg:py-10">
        <div className="flex gap-4">
          <div className="flex items-center gap-2">
            <div className="h-6 w-6 rounded-full bg-gray" />
            <div className="h-4 w-14 rounded-sm bg-gray" />
          </div>
          <div className="flex items-center gap-2">
            <div className="h-6 w-6 rounded-full bg-gray" />
            <div className="h-4 w-14 rounded-sm bg-gray" />
          </div>
        </div>
        <div className="">
          <div className="h-6 w-20 rounded-sm bg-gray" />
        </div>
      </div>
    </div>
  )
}

export default PostAnnounce
