import { twJoin } from 'tailwind-merge'
import { PostAttachmentInterface } from 'modules/community/types/post-attachment'

interface ImageAttachmentPreviewProps {
  attachment: PostAttachmentInterface
}

export default function ImageAttachmentPreview({ attachment }: ImageAttachmentPreviewProps) {
  return (
    <div
      className={twJoin('h-40 w-36 overflow-hidden rounded-lg', !attachment.url && 'bg-slate-100')}
    >
      {!!attachment.url && (
        <img
          src={attachment.url}
          alt=""
          className="h-full w-full object-cover transition-all group-hover:scale-110"
        />
      )}
    </div>
  )
}
