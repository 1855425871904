import React from 'react'
import { useSlate } from 'slate-react'
import LinkIcon from '../../shared/icons/link-icon'
import UnlinkIcon from '../../shared/icons/unlink-icon'
import { isBlockActive, unwrapLink } from '../community/hooks/slate-editor-utils'

type LinkButtonProps = {
  format: string
  label: string
  setLinkIsEditing: any
}

export default function LinkButton({ format, label, setLinkIsEditing }: LinkButtonProps) {
  const editor = useSlate()
  const active = isBlockActive(editor, format)
  return (
    <button
      className={`relative z-10 inline-flex items-center px-2 py-1 ${
        active ? 'fill-black' : 'fill-bluegray hover:fill-black'
      } `}
      onMouseDown={event => {
        event.preventDefault()
        if (active) {
          unwrapLink(editor)
        } else {
          setLinkIsEditing(true)
        }
      }}
      title={label}
      type="button"
    >
      {isBlockActive(editor, format) ? <UnlinkIcon height="15px" /> : <LinkIcon height="15px" />}
    </button>
  )
}
