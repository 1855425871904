import { useState } from 'react'
import { usePopper } from 'react-popper'
import { useTranslation } from 'next-i18next'
import { Loader } from 'shared/components/loader'
import useLastFewLikes from 'modules/community/hooks/use-last-few-likes'

interface LikeDetailPopoverProps {
  postId: number
  likesCount: number
  referenceElement: HTMLElement | null
}

const MAX_DISPLAYED_LIKES = 7

export const LikeDetailPopover = ({
  likesCount,
  referenceElement,
  postId,
}: LikeDetailPopoverProps) => {
  const { t } = useTranslation()
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null)
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'top',
    strategy: 'fixed',
  })

  const { lastFewLikes, isLoading } = useLastFewLikes({
    postId: String(postId),
  })

  const overflowLikes = likesCount > MAX_DISPLAYED_LIKES ? likesCount - MAX_DISPLAYED_LIKES : 0

  return (
    <div ref={setPopperElement} style={styles.popper} {...attributes.popper} className="z-10">
      <div className="pb-3">
        <div className="w-fit min-w-[120px] max-w-[180px] rounded-md bg-white px-5 py-4 text-sm text-darkblue shadow-[0px_0px_20px_0px_#062A4033]">
          {isLoading ? (
            <div className="flex items-center justify-center">
              <Loader className="h-8 w-8 text-blue" />
            </div>
          ) : (
            <>
              {lastFewLikes?.slice(0, MAX_DISPLAYED_LIKES).map(like => (
                <p
                  className="truncate text-xs [&:not(:first-child)]:pt-2.5"
                  key={like.createdAt + like.displayName}
                >
                  {like.displayName}
                </p>
              ))}
              {overflowLikes > 0 && (
                <p className="whitespace-nowrap pt-2.5 text-xs text-black/60">
                  {t('post.likes.popup.and_others', { count: overflowLikes })}
                </p>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  )
}
