import React from 'react'

function NumericListIcon(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg width="19" height="16" viewBox="0 0 19 16" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M0 13H2V13.5H1V14.5H2V15H0V16H3V12H0V13ZM1 4H2V0H0V1H1V4ZM0 7H1.8L0 9.1V10H3V9H1.2L3 6.9V6H0V7ZM5 1V3H19V1H5ZM5 15H19V13H5V15ZM5 9H19V7H5V9Z" />
    </svg>
  )
}

export default NumericListIcon
