import React from 'react'
import { RenderElementProps } from 'slate-react'
import { ImageElement } from 'modules/community/components/editor/elements/image'
import { Mention } from 'modules/community/components/editor/elements/mention/mention'
import { CustomElement } from 'modules/community/types/CustomEditor'

export interface CustomRenderElementProps extends RenderElementProps {
  element: CustomElement
}

export function Element({ attributes, children, element }: CustomRenderElementProps) {
  switch (element.type) {
    case 'bulleted-list':
      return (
        <ul className="list-disc pl-4" {...attributes}>
          {children}
        </ul>
      )
    case 'link':
      return (
        <a {...attributes} href={element.url} className="red text-blue underline">
          {children}
        </a>
      )
    case 'list-item':
      return <li {...attributes}>{children}</li>
    case 'numbered-list':
      return (
        <ol className="list-decimal pl-4" {...attributes}>
          {children}
        </ol>
      )
    case 'image':
      return (
        <ImageElement attributes={attributes} element={element}>
          {children}
        </ImageElement>
      )
    case 'mention':
      return (
        <Mention attributes={attributes} element={element}>
          {children}
        </Mention>
      )
    default:
      return <p {...attributes}>{children}</p>
  }
}
