import { ReactTag } from '@headlessui/react/dist/types'
import { PropsWithChildren } from 'react'
import { UseSortableArguments, useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'

type SortableProps = UseSortableArguments & PropsWithChildren & { as?: ReactTag }

export default function Sortable({ children, as = 'li', ...rest }: SortableProps) {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ ...rest })

  const Component = as

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  }

  return (
    <Component style={style} ref={setNodeRef} {...attributes} {...listeners}>
      {children}
    </Component>
  )
}
