import React, { useEffect, useRef } from 'react'
import ReactDOM from 'react-dom'
import { Mention } from '../elements/mention/mention'
import { MentionInterface } from '../elements/mention/mention.types'

const parseTextWithMentions = (text: string, mentions: MentionInterface[]): string => {
  const parts = []
  let lastIndex = 0

  mentions?.forEach(({ userId, displayName, profileImage, positionStart, positionEnd }) => {
    if (positionStart > lastIndex) {
      parts.push(text.substring(lastIndex, positionStart))
    }

    const dataAvatarUrl = profileImage ? `data-avatar-url="${profileImage}"` : ''
    const dataId = userId ? `data-id="${userId}"` : ''

    parts.push(
      `<span data-type="mention" contenteditable="false" ${dataAvatarUrl} data-cy="mention-${displayName}" ${dataId}>@${displayName}</span>`,
    )

    lastIndex = positionEnd
  })

  if (lastIndex < text.length) {
    parts.push(text.substring(lastIndex))
  }

  return parts.join('')
}

interface TextWithMentionsProps extends React.HTMLProps<HTMLDivElement> {
  text: string
  mentions: MentionInterface[]
  truncateLength?: number
}

export const TextWithMentions = ({
  text,
  mentions,
  truncateLength,
  ...rest
}: TextWithMentionsProps) => {
  const containerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (containerRef.current) {
      const htmlContent = parseTextWithMentions(text, mentions)
      if (truncateLength) {
        containerRef.current.innerHTML =
          htmlContent.slice(0, truncateLength).slice(0, -4) + '...</p>'
      } else {
        containerRef.current.innerHTML = htmlContent
      }

      const mentionElements = containerRef.current.querySelectorAll('[data-type="mention"]')
      mentionElements?.forEach(element => {
        const id = element.getAttribute('data-id')
        const displayName = element.getAttribute('data-cy')?.split('-').pop()
        const profileImageUrl = element.getAttribute('data-avatar-url')

        ReactDOM.render(
          <Mention
            element={{
              displayName: displayName || '',
              id: Number(id) || 0,
              profileImageUrl: profileImageUrl || '',
              type: 'mention',
              children: [{ text: '' }],
            }}
          />,
          element,
        )
      })
    }
  }, [text, mentions])

  return <div ref={containerRef} {...rest} />
}
