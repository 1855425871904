import { useId } from 'react'
import { useTranslation } from 'next-i18next'
import { twMerge } from 'tailwind-merge'
import TrashIconThin from 'shared/icons/trash-icon-thin'
import { usePostAttachmentsContext } from '../context'

interface RemoveAttachmentButtonProps {
  id: number
  className?: string
}

export default function RemoveAttachmentButton(props: RemoveAttachmentButtonProps) {
  const id = useId()
  const { t } = useTranslation()
  const { removeAttachment } = usePostAttachmentsContext()

  return (
    <button
      className={twMerge('rounded-full bg-red p-1', props.className)}
      onClick={e => {
        e.stopPropagation()
        removeAttachment(props.id)
      }}
      aria-labelledby={id}
      type='button'
    >
      <span id={id} hidden>
        {t('global.remove')}
      </span>
      <TrashIconThin className="h-5 w-5 text-white" fill="CurrentColor" />
    </button>
  )
}
