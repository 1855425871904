import { ComponentProps } from 'react'
import PlainUploader from 'shared/components/plain-uploader'
import { useUploadImage } from 'shared/hooks/use-upload-image'
import { fileInputAcceptTypes } from 'shared/utils/file-input-accept-types'
import {
  AttachmentTypeEnum,
  AttachmentUploadStatusEnum,
} from 'modules/community/types/post-attachment'
import { usePostAttachmentsContext } from './context'

interface ImageAttachmentProps extends ComponentProps<'input'> {
  className?: string
}

const acceptTypes = fileInputAcceptTypes.jpg_jpeg_png

export default function ImageAttachment(props: ImageAttachmentProps) {
  const { addError, addAttachment, updateAttachment } = usePostAttachmentsContext()
  const { uploadImage } = useUploadImage()

  const handleSelectFile = async (file: File) => {
    const attachmentId = addAttachment({
      status: AttachmentUploadStatusEnum.Uploading,
      type: AttachmentTypeEnum.Image,
    })

    try {
      const uploaded = await uploadImage({ file })
      updateAttachment(attachmentId, {
        status: AttachmentUploadStatusEnum.Success,
        url: uploaded.path,
        dataFileId: uploaded.id,
      })
    } catch {
      addError(attachmentId, 'Failed to upload image')
    }
  }

  return (
    <PlainUploader
      className={props.className}
      onSelect={handleSelectFile}
      onError={error => addError(0, error)}
      acceptTypes={acceptTypes}
    />
  )
}
