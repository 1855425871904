import {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import { AttachmentGallery } from 'modules/community/components/attachment-gallery/attachment-gallery'
import {
  AttachmentUploadStatusEnum,
  PostAttachmentInterface,
} from 'modules/community/types/post-attachment'

interface PostAttachmentsContextInterface {
  attachments?: PostAttachmentInterface[]
  initialGallerySlide?: number
  isGalleryVisible: boolean
  isEditable: boolean
  setIsGalleryVisible: (isGalleryVisible: boolean, initialSlide?: number) => void
  setInitialGallerySlide: (initialGallerySlide?: number) => void
  addAttachment: (attachment: PostAttachmentInterface) => number
  removeAttachment: (id: number) => void
  addError: (id: number, error: string) => void
  updateAttachment: (id: number, attachment: Partial<PostAttachmentInterface>) => void
  reorder: (activeId: number, targetId: number) => void
}

const PostAttachmentsContext = createContext<PostAttachmentsContextInterface>({
  attachments: [],
  isGalleryVisible: false,
  isEditable: false,
  setInitialGallerySlide: () => {},
  setIsGalleryVisible: () => {},
  addAttachment: () => 0,
  removeAttachment: () => {},
  addError: () => {},
  updateAttachment: () => {},
  reorder: () => {},
})

export const usePostAttachmentsContext = () => useContext(PostAttachmentsContext)

interface PostAttachmentsProviderProps extends PropsWithChildren {
  attachments?: PostAttachmentInterface[]
  isEditable?: boolean
  onChange?: (attachments: PostAttachmentInterface[]) => void
}

export const PostAttachmentsProvider = (props: PostAttachmentsProviderProps) => {
  const [initialGallerySlide, setInitialGallerySlide] = useState<number>()
  const [isGalleryVisible, setIsGalleryVisible] = useState<boolean>(false)
  const [attachments, setAttachments] = useState<PostAttachmentInterface[]>(props.attachments ?? [])

  const addAttachment = (attachment: PostAttachmentInterface) => {
    const newIndex = attachments.length
    setAttachments(prev => prev.concat(attachment))
    return newIndex
  }

  const removeAttachment = useCallback(
    (dataFileId: number) => setAttachments(prev => prev.filter(a => a.dataFileId !== dataFileId)),
    [],
  )

  const addError = useCallback(
    (index: number, error: string) =>
      setAttachments(prev =>
        prev.map((attachment, i) =>
          i === index
            ? { ...attachment, error, status: AttachmentUploadStatusEnum.Failed }
            : attachment,
        ),
      ),
    [],
  )

  const updateAttachment = useCallback(
    (index: number, attachment: Partial<PostAttachmentInterface>) =>
      setAttachments(prev => prev.map((a, i) => (i === index ? { ...a, ...attachment } : a))),
    [],
  )

  const reorder = useCallback(
    (activeId: number, targetId: number) =>
      setAttachments(prev => {
        const activeIndex = prev.findIndex(a => a.dataFileId === activeId)
        const targetIndex = prev.findIndex(a => a.dataFileId === targetId)
        const copy = prev.slice()
        const element = copy.splice(activeIndex, 1)[0]
        copy.splice(targetIndex, 0, element)
        return copy
      }),
    [],
  )

  useEffect(() => {
    if (props.onChange) {
      props.onChange(attachments)
    }
  }, [attachments, props.onChange])

  return (
    <PostAttachmentsContext.Provider
      value={{
        attachments,
        isGalleryVisible,
        initialGallerySlide,
        isEditable: props.isEditable ?? false,
        setIsGalleryVisible,
        setInitialGallerySlide,
        addAttachment,
        removeAttachment,
        addError,
        updateAttachment,
        reorder,
      }}
    >
      {props.children}
      {isGalleryVisible && <AttachmentGallery />}
    </PostAttachmentsContext.Provider>
  )
}
