import React from 'react'

export interface FieldLabelProps {
  wrapperAs?: React.ElementType
  labelRightElement?: React.ReactNode
  wrapperProps?: Record<string, unknown>
  label?: React.ReactNode
  required?: boolean
  labelClassName?: string
}

export function FieldLabel({
  wrapperAs: WrapperAs = 'div',
  wrapperProps = { className: 'flex justify-between gap-2' },
  label,
  required,
  labelClassName,
  labelRightElement,
}: FieldLabelProps) {
  return label || required || labelRightElement ? (
    <WrapperAs {...wrapperProps}>
      {(label || required) && (
        <span className={`flex ${labelClassName}`}>
          {label} {required && <i className="text-danger">*</i>}
        </span>
      )}
      {labelRightElement}
    </WrapperAs>
  ) : null
}
