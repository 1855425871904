import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { RenderElementProps, RenderLeafProps, withReact } from 'slate-react'
import { Descendant, Editor, Range, createEditor } from 'slate'
import { useDebouncedValue } from 'shared/hooks/use-debounced-value'
import { insertText, serializeHTML } from 'modules/community/hooks/slate-editor-utils'
import { useSuggestionsList } from 'modules/community/hooks/use-suggestions-list'
import { withImages } from '../plugins/with-images'
import { withLinks } from '../plugins/with-links'
import { withMentions } from '../plugins/with-mentions'
import { Element } from '../utils/element'
import { Leaf } from '../utils/leaf'

interface UseEditorProps {
  initialContent: Descendant[]
  path: string
}

export const useEditor = ({ initialContent, path }: UseEditorProps) => {
  const [content, setContent] = useState<Descendant[]>(initialContent)
  const [serializedTextHtml, setSerializedTextHtml] = useState('')
  const [target, setTarget] = useState<Range | null>(null)
  const [search, setSearch] = useState('')
  const [debouncedSearch] = useDebouncedValue(search, 300)
  const [contentError, setContentError] = useState('')

  const editor = useMemo(() => withImages(withLinks(withReact(withMentions(createEditor())))), [])
  const renderElement = useCallback((props: RenderElementProps) => <Element {...props} />, [])
  const renderLeaf = useCallback((props: RenderLeafProps) => <Leaf {...props} />, [])

  const {
    data: suggestions,
    isValidating: suggestionsLoading,
    mutate: mutateSuggestions,
  } = useSuggestionsList(debouncedSearch)

  const updateEditorContent = (value: Descendant[]) => {
    setContentError('')
    setContent(value)
    const { selection } = editor

    // NOTE: This code for a display list of mentions when typing '@'
    if (selection && Range.isCollapsed(selection)) {
      const [start] = Range.edges(selection)
      const wordBefore = Editor.before(editor, start, { unit: 'word' })
      const before = wordBefore && Editor.before(editor, wordBefore)
      const beforeRange = before && Editor.range(editor, before, start)
      const beforeText = beforeRange && Editor.string(editor, beforeRange)
      const beforeMatch = beforeText && beforeText.match(/(?:^|\s)@([^\s@]*)(\s|$)/)
      const after = Editor.after(editor, start)
      const afterRange = Editor.range(editor, start, after)
      const afterText = Editor.string(editor, afterRange)
      const afterMatch = afterText.match(/^(\s|$)/)

      const twoCharsBefore =
        wordBefore &&
        Editor.before(
          editor,
          { ...wordBefore, offset: wordBefore.offset },
          { unit: 'character', distance: 2 },
        )
      const twoCharsRange = twoCharsBefore && Editor.range(editor, twoCharsBefore, start)
      const twoCharsText = twoCharsRange && Editor.string(editor, twoCharsRange)

      const isEmptyBeforeAtRegular = new RegExp(/(?:^|\s)@/)
      const isEmptyBeforeAt = isEmptyBeforeAtRegular.test(twoCharsText || '')

      if (beforeMatch?.[1] && afterMatch && isEmptyBeforeAt) {
        // NOTE: This is for contains mention '@asd'
        setTarget(beforeRange || twoCharsRange || null)
        setSearch(beforeMatch[1])
        return
      } else {
        // NOTE: This is for empty mention '@'
        const pointBefore = Editor.before(editor, start, { distance: 2 })
        const pointRange = pointBefore && Editor.range(editor, pointBefore, start)
        const characterBefore = pointRange && Editor.string(editor, pointRange)

        const pointNormal = Editor.before(editor, start)
        const pointNormalRange = pointNormal && Editor.range(editor, pointNormal, start)

        if (characterBefore === ' @' || characterBefore === '@') {
          setTarget(pointNormalRange || pointRange || beforeRange || null)
          setSearch('')
          return
        }
      }
    }

    setTarget(null)
  }

  const insertEmoji = (emoji: string) => {
    insertText(editor, emoji)
  }

  useEffect(() => {
    if (path) {
      mutateSuggestions()
    }
  }, [debouncedSearch])

  useEffect(() => {
    const textHtml = serializeHTML(editor)
    setSerializedTextHtml(textHtml || '')
  }, [content])

  return {
    editor,
    content,
    setContent,
    renderElement,
    renderLeaf,
    updateEditorContent,
    target,
    setTarget,
    suggestions,
    suggestionsLoading,
    serializedTextHtml,
    insertEmoji,
    contentError,
    setContentError,
  }
}
