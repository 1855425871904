import React, { useCallback } from 'react'
import { usePopper } from 'react-popper'
import { RenderElementProps } from 'slate-react'
import { useHover } from 'shared/hooks/use-hover'
import { MentionElement } from 'modules/community/types/CustomEditor'

export interface CustomRenderMentionProps
  extends Partial<Pick<RenderElementProps, 'attributes' | 'children'>> {
  element: MentionElement
}

export const Mention = ({ attributes, children, element }: CustomRenderMentionProps) => {
  const { ref: hoverRef, hovered } = useHover<HTMLSpanElement>()
  const [referenceElement, setReferenceElement] = React.useState<null | HTMLSpanElement>(null)
  const [popperElement, setPopperElement] = React.useState<null | HTMLDivElement>(null)
  const { styles: popperStyles, attributes: popperAttributes } = usePopper(
    referenceElement,
    popperElement,
    {
      placement: 'top-start',
      strategy: 'fixed',
    },
  )

  const setRef = useCallback(
    (node: HTMLSpanElement) => {
      // @ts-ignore
      hoverRef.current = node
      setReferenceElement(node)
    },
    [setReferenceElement],
  )

  return (
    <span ref={setRef} className="relative inline-block">
      <span
        {...attributes}
        className="inline-block align-baseline text-[#2e6ef4] no-underline hover:underline"
        contentEditable={false}
        data-cy={`mention-${element.displayName.replace(' ', '-')}`}
        data-avatar-url={element.profileImageUrl || ''}
        data-id={element.id || 0}
      >
        @{element.displayName}
        {children}
      </span>
      {hovered && (
        <div
          ref={setPopperElement}
          style={{ zIndex: 100, ...popperStyles.popper }}
          {...popperAttributes}
          contentEditable={false}
        >
          <div className="z-[10000] my-1.5 flex w-max max-w-xs items-center justify-center gap-2.5 overflow-hidden rounded-lg bg-white p-4 shadow-[rgba(29,_28,_29,_0.13)_0px_0px_0px_1px,_rgba(0,_0,_0,_0.08)_0px_4px_12px_0px]">
            {/* TODO: Delete when this task complete - https://app.shortcut.com/systeme/story/27961/extra-info-in-mentions-in-comments-response */}
            {element.profileImageUrl && (
              <img
                className="h-11 w-11 rounded-full"
                src={element.profileImageUrl}
                width={46}
                height={46}
                alt={element.displayName}
              />
            )}
            <p className="font-black">{element.displayName}</p>
          </div>
        </div>
      )}
    </span>
  )
}
