import { Dialog, DialogPanel, Transition, TransitionChild } from '@headlessui/react'
import { useRollbar } from '@rollbar/react'
import React, { Fragment } from 'react'
import toast from 'react-hot-toast'
import { useTranslation } from 'next-i18next'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { twMerge } from 'tailwind-merge'
import { LocaleEnum } from '../../../../shared/enums/locale-enum'
import useDomainAssets from '../../../../shared/hooks/use-domain-assets'
import useUser from '../../../../shared/hooks/use-user'
import CloseIcon from 'shared/icons/close-icon'
import CommentOutlinedIcon from 'shared/icons/comment-outlined-icon'
import { PostAttachmentsProvider } from 'modules/attachments/components/context'
import AttachmentPreviewItem from 'modules/attachments/components/preview/preview-item'
import { CommentsList } from 'modules/comments/components'
import { CommentsTypeEnum } from 'modules/comments/types'
import { PostInterface } from 'modules/community/types/post-interface'
import { timeSince } from 'modules/post/utils/time-utils'
import useIsUserMember from '../../hooks/use-is-user-member'
import { TextWithMentions } from '../editor/utils/deserealizeMentionToHTML'
import LikeButton from '../like/like-button'
import { PostAuthorInfo } from './author-info'

export interface BaseModalProps {
  className?: string
  opened: boolean
  onClose: () => void
  post: PostInterface
  handleLikeClick?: () => void
  dashboardLocale?: LocaleEnum
  commentsCount?: number
  topicPath: string
}

function PostModal({
  className,
  onClose,
  opened,
  post,
  handleLikeClick,
  dashboardLocale,
  topicPath,
  commentsCount,
}: BaseModalProps) {
  const rollbar = useRollbar()
  const { t } = useTranslation()
  const router = useRouter()
  const { communityPath } = router.query
  const { isUserMember } = useIsUserMember()
  const { domainAssets } = useDomainAssets()

  const { user } = useUser()

  return (
    <PostAttachmentsProvider attachments={post.attachments}>
      <Transition show={opened} as={Fragment}>
        <Dialog className="fixed z-[20]" open={opened} onClose={onClose}>
          <TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/60 transition-opacity" />
          </TransitionChild>

          <button className="group fixed right-5 top-5 z-[1001] hidden h-10 w-10 items-center justify-center rounded-full bg-bluegray hover:bg-white md:flex">
            <CloseIcon className="h-5 w-5 fill-white group-hover:fill-black" />
          </button>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full w-full items-end justify-center text-center sm:items-center">
              <TransitionChild
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <DialogPanel
                  className={`relative min-h-screen w-full max-w-[800px] transform bg-white text-left shadow-xl transition-all md:mt-16 md:min-h-fit md:rounded-lg ${
                    className || ''
                  }`}
                >
                  <div className="sticky top-0 z-[100] flex h-12 w-full items-center border-b border-gray-300/70 bg-white px-8 py-2 md:hidden">
                    <div className="flex w-full min-w-0">
                      <div className="flex min-w-0 items-center gap-2 text-darkblue">
                        <button className="flex items-center justify-center" onClick={onClose}>
                          <CloseIcon className="h-8 w-8 fill-bluegray" />
                        </button>
                        <img
                          src={domainAssets?.logoUrl}
                          alt="logo"
                          className="h-6 w-6 rounded-full"
                          width={24}
                          height={24}
                        />
                        <p className="overflow-hidden text-ellipsis whitespace-nowrap text-sm font-bold">
                          {post.title}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="border-b border-gray-300/70 p-8 pb-2 pt-4 md:pt-8">
                    <div className="flex flex-wrap items-center gap-2.5">
                      <PostAuthorInfo author={post.author} userId={post.userId} />
                      <div className="bluegray inline lg:block">
                        <span>{timeSince(new Date(post.createdAt), dashboardLocale)}</span>{' '}
                        {t('community.pages_on')}{' '}
                        <Link className="font-semibold text-darkblue" href={topicPath} shallow>
                          #{post.topic.name}
                        </Link>
                      </div>
                    </div>
                    <p className="community-post-title pb-2 md:pb-3">{post.title}</p>
                    <TextWithMentions
                      className={twMerge('overflow-hidden text-sm text-darkblue/90 lg:text-base')}
                      text={post.content}
                      mentions={post.mentions}
                    />
                    {!!post?.attachments?.length && (
                      <div className="mt-7 flex flex-row gap-3 overflow-x-auto">
                        {post.attachments.map((attachment, index) => (
                          <AttachmentPreviewItem
                            className="shrink-0"
                            index={index}
                            key={attachment.dataFileId}
                            attachment={attachment}
                          />
                        ))}
                      </div>
                    )}
                    <div className="mt-3 flex items-center gap-3">
                      {handleLikeClick && (
                        <LikeButton
                          isLiked={post.isLiked}
                          likesCount={post.likesCount}
                          handleLikeClick={handleLikeClick}
                          postId={post.id}
                        />
                      )}
                      <div className="flex items-center gap-2 rounded-md px-1.5 py-2">
                        <CommentOutlinedIcon className="h-6 w-6 text-bluegray transition-colors group-hover:text-blue" />
                        {commentsCount || ''}
                      </div>
                    </div>
                  </div>
                  <div className="min-h-[48px] px-7 py-2">
                    {user && (
                      <CommentsList
                        path={String(communityPath)}
                        commentsType={CommentsTypeEnum.Community}
                        onError={() => toast.error(t('comments.error.error-loading-comments'))}
                        pageId={post.id}
                        locale={user.dashboardLocale}
                        readOnly={!isUserMember}
                        rollbar={rollbar}
                      />
                    )}
                  </div>
                </DialogPanel>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </Transition>
    </PostAttachmentsProvider>
  )
}

export default PostModal
