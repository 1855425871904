import { useCallback, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { Emoji } from 'emoji-mart'
import dynamic from 'next/dynamic'
import EmojiIcon from 'shared/icons/emoji-icon'
import AttachmentsPreview from 'modules/attachments/components/preview'
import { PostAttachmentInterface } from 'modules/community/types/post-attachment'
import { PostAttachmentsProvider } from '../../../../attachments/components/context'
import ImageAttachment from '../../../../attachments/components/image-attachment'

const EmojiPicker = dynamic(() => import('../../emoji-picker'))

interface PostAttachmentsProps {
  onAddEmoji: (emoji: Emoji) => void
}

export default function PostAttachments({ onAddEmoji }: PostAttachmentsProps) {
  const { setValue, control } = useFormContext()
  const [emojiPickerVisible, setEmojiPickerVisible] = useState(false)

  const handleChange = useCallback(
    (attachments: PostAttachmentInterface[]) => setValue('attachments', attachments),
    [],
  )

  const handleAddEmoji = (emoji: Emoji) => {
    setEmojiPickerVisible(false)
    onAddEmoji(emoji)
  }

  return (
    <Controller
      control={control}
      name="attachments"
      render={({ field }) => (
        <PostAttachmentsProvider isEditable onChange={handleChange} attachments={field.value}>
          <div className="flex flex-col gap-4">
            <AttachmentsPreview />
            <div className="mb-6 flex items-center gap-6">
              <EmojiIcon
                className="cursor-pointer fill-bluegray"
                onClick={() => setEmojiPickerVisible(prev => !prev)}
              />
              {emojiPickerVisible && (
                <EmojiPicker
                  setEmojiPickerVisible={setEmojiPickerVisible}
                  onEmojiSelect={handleAddEmoji}
                />
              )}
              <ImageAttachment />
            </div>
          </div>
        </PostAttachmentsProvider>
      )}
    />
  )
}
