import { ComponentProps, PropsWithChildren } from 'react'
import {
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import { SortableContext, sortableKeyboardCoordinates } from '@dnd-kit/sortable'
import Sortable from 'shared/components/sortable'
import { usePostAttachmentsContext } from '../context'
import AttachmentPreviewItem from './preview-item'

const Wrapper = ({
  children,
  attachment,
}: PropsWithChildren & ComponentProps<typeof AttachmentPreviewItem>) => {
  if (!attachment.dataFileId) {
    return <li>{children}</li>
  }

  return (
    <Sortable id={attachment.dataFileId} data={attachment}>
      {children}
    </Sortable>
  )
}

export default function AttachmentsPreview() {
  const { attachments, reorder } = usePostAttachmentsContext()

  const attachmentIds = attachments?.filter(a => !!a.dataFileId).map(a => a.dataFileId!) ?? []

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event
    reorder(active.id as number, over?.id as number)
  }

  const sensors = useSensors(
    useSensor(PointerSensor, { activationConstraint: { distance: 5 } }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  )

  return (
    <ul className="customScroll flex max-w-full gap-3 overflow-x-auto">
      <DndContext onDragEnd={handleDragEnd} sensors={sensors}>
        <SortableContext items={attachmentIds}>
          {attachments?.map((attachment, i) => (
            <Wrapper key={attachment.dataFileId} attachment={attachment}>
              <AttachmentPreviewItem index={i} attachment={attachment} />
            </Wrapper>
          ))}
        </SortableContext>
      </DndContext>
    </ul>
  )
}
