import useUser, { isAuthorizedUser } from 'shared/hooks/use-user'
import { PostStatusEnum } from '../enums/post-status-enum'
import { PostInterface } from '../types/post-interface'
import { isAdminWorkspace, isAssistantWorkspace } from './check-admin-workspace-role'
import useIsUserMember from './use-is-user-member'

export const useIsPostEditable = (post: PostInterface) => {
  const { user, isOwner } = useUser()
  const { member } = useIsUserMember()
  const isWsAssistant = user && isAssistantWorkspace(user)
  const isWsAdmin = user && isAdminWorkspace(user)

  const isAuthor = user && isAuthorizedUser(user) && post.userId === member?.userId
  const isApproved = post.status === PostStatusEnum.Approved
  const isEditable =
    (isOwner || isWsAdmin || isWsAssistant) && isApproved && !post.author?.workspaceRole

  if (isAuthor || isEditable) {
    return true
  }

  return false
}
